import { TrackingService } from '../tracking/TrackingService';

export class UpTeaserServicepageService {
	constructor(private readonly trackingService: TrackingService) {

	}

	init() {
		const teaser = document.querySelector(
			'#up-teaser-l-servicepage-to-link-container');

		if (teaser) {
			this.trackTeaserView(teaser);
			this.trackTeaserSeen(teaser);
			this.trackTeaserClicked(teaser);
		}
	}

	private trackTeaserView(teaser: Element) {
		const trackingPayload = {
			up_Promo: 'view',
			up_TeaserType: teaser?.getAttribute('data-tracking-teaser-type'),
			up_TeaserSize: 'MediaObject100'
		};

		this.trackingService.sendMerge(trackingPayload);
	}

	private trackTeaserSeen(teaser: Element) {
		const observer = new IntersectionObserver((observerEntries, observer) => {
			const observerEntry = observerEntries[0];
			if (!observerEntry.isIntersecting) {
				return;
			}

			observer.unobserve(teaser);
			const trackingPayload = {
				up_Promo: 'seen',
				up_TeaserType: teaser?.getAttribute('data-tracking-teaser-type'),
				up_TeaserSize: 'MediaObject100'
			};

			this.trackingService.sendEvent(trackingPayload);
		}, { threshold: 0.75 });

		observer.observe(teaser);
	}

	private trackTeaserClicked(teaser: Element) {
		teaser.addEventListener('click', () => {
			const teaserType = teaser?.getAttribute('data-tracking-teaser-type');
			const trackingPayload = {
				up_Promo: teaserType === 'Login' ? 'ToLogin' : 'ToUpHome',
				up_TeaserType: teaserType,
				up_TeaserSize: 'MediaObject100'
			};

			this.trackingService.sendEvent(trackingPayload);
		});
	}
}
